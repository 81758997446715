import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { get, isEmpty, suggestionsActions } from '@docavenue/core';
import { FieldProps } from 'formik';
import { Alert } from '@mui/material';
import { cn } from '@kiiwi/ui';
import Autocomplete from '../Autocomplete';
import { checkTLCStatusSuggestion } from '@/src/actions/async';
import { isDemoAccountSelector } from '@/src/selectors';

import SuggestionItem from '../SuggestionItem/SuggestionItem';
import { suggestionsEmptyQuery } from '@/src/actions';
import { capitalizeFirstLetter } from '@/src/utils';
import useDefaultSuggestions from '@/src/hooks/useDefaultSuggestions';
import { analyticsEvent } from '@/src/analytic_tag_manager';
import FontAwesomeIcon from '@/components/atoms/FontawesomeIcon/FontawesomeIcon';
import { APP_ROUTES } from '@/src/constants';
import { useTranslation } from '@/src/i18n';

type Props = {
  focusInput: boolean;
  withoutRedirection: boolean;
  onChangeCallback?: Function;
} & FieldProps;

const ALGOLIA_TRACKING = false;

const SILENT_DELAY = 500; // The time to wait before check status of tlc

const SuggestionInput = ({
  field: { name, value },
  form: { setFieldValue },
  focusInput,
  withoutRedirection,
  onChangeCallback,
  ...rest
}: Props) => {
  const router = useRouter();
  const isHomepage = router.pathname === APP_ROUTES.HOME;
  const { data: defaultSuggestions } = useDefaultSuggestions();
  const suggestions = useSelector(state => get(state, 'suggestions.items'));
  const query = useSelector(state => get(state, 'suggestions.query'));
  const isDemoAccount = useSelector(isDemoAccountSelector);
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const timer = React.useRef<any>(null!);
  const [isError, setIsError] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(
    () => () => {
      // Clean timeout when unmount
      if (timer.current) clearTimeout(timer.current);
    },
    [],
  );
  const trackAlgolia = React.useCallback(e => {
    if (!ALGOLIA_TRACKING) return;
    const target = e.currentTarget;
    if (target.dataset.trackQueryid) {
      const trackInfo = {
        index: target.dataset.trackIndex,
        queryID: target.dataset.trackQueryid,
        objectIDs: [target.dataset.trackObjectid],
      };
      try {
        window.aa?.('clickedObjectIDsAfterSearch', {
          eventName: target.dataset.trackEventName,
          positions: [parseInt(target.dataset.trackPosition, 10)],
          ...trackInfo,
        });
        window.sessionStorage?.setItem(
          'lastTrackInfo',
          JSON.stringify(trackInfo),
        );
        // eslint-disable-next-line no-empty
      } catch {}
    }
  }, []);

  return (
    <div
      className="input-dropdown suggestion"
      data-cy="search-suggestion-input"
    >
      <Autocomplete
        focusInput={focusInput}
        inputClassName="!shadow-none placeholder:text-content-primary text-content-primary"
        inputContainerClassName="flex flex-row items-center pl-4 gap-x-2"
        leftComponent={
          <FontAwesomeIcon
            className="size-4 text-content-primary"
            name="magnifying-glass:regular"
          />
        }
        onSearch={searchValue => {
          if (!searchValue) {
            dispatch(suggestionsEmptyQuery());
            return;
          }
          if (timer.current) {
            clearTimeout(timer.current);
          }
          const action = suggestionsActions.searchViaBackend(searchValue, {
            isDemo: isDemoAccount,
          });
          action.onSuccess = items => {
            setIsLoading(false);
            setIsError(false);
            timer.current = setTimeout(
              () => checkTLCStatusSuggestion(dispatch)(items),
              SILENT_DELAY,
            );
          };
          action.onError = () => {
            setIsLoading(false);
            setIsError(true);
          };
          setIsLoading(true);
          dispatch(action);
        }}
        onChange={item => {
          if (!item) return;
          if (item?.shortName !== 'suggestion_tlc') {
            setFieldValue(name, item);
            dispatch(suggestionsActions.setItem(item));
          }
          if (item.url && !withoutRedirection) {
            if (item?.shortName === 'suggestion_tlc') {
              analyticsEvent({
                category: 'Direct_TLC_proposition',
                action: 'Search_Big_Button',
              });
            }
            router.push(item.url);
            return;
          }
          if (onChangeCallback) onChangeCallback(item.shortName);
        }}
        suggestions={
          (!query || (query && isEmpty(suggestions))) && !withoutRedirection
            ? defaultSuggestions
            : suggestions
        }
        initialInputValue={value ? value.label : ''}
        renderItem={(suggestionItem, highlightedText, index) => (
          <div
            onClick={trackAlgolia}
            data-track-event-name={`Click${capitalizeFirstLetter(
              suggestionItem?.objectType ?? '',
            )}`}
            data-track-position={index + 1}
            data-track-queryid={suggestionItem?._algolia?.queryID}
            data-track-objectid={suggestionItem?.objectID}
            data-track-index={suggestionItem?._algolia?.index}
          >
            <SuggestionItem
              suggestion={suggestionItem}
              highlightedText={highlightedText}
            />
          </div>
        )}
        breakCondition={(current, prev, index) =>
          current.objectType !== prev.objectType &&
          index > 1 &&
          [prev.objectType, current.objectType].sort().join(',') !==
            'expertise,speciality'
        }
        noAutoHighlight={!query || (query && isEmpty(suggestions))}
        isLoading={isLoading}
        maxLength={200}
        dropdownClassName={cn(
          'border-y border-global-neutral',
          isHomepage ? 'mt-7' : 'mt-5',
        )}
        topElement={
          isError && (
            <Alert severity="error" icon={false}>
              {t('error:search_unavailable')}
            </Alert>
          )
        }
        {...rest}
      />
    </div>
  );
};

export default SuggestionInput;
