import { suggestionsActions, asyncActions } from '@docavenue/core';
import { tlcCardsActions } from '.';

// eslint-disable-next-line import/prefer-default-export
export const checkTLCStatusSuggestion = (dispatch: Function) => async (
  items: any,
) => {
  const practitionerIds = items
    .filter(item => item.objectType === 'practitioner')
    .map(item => item.objectID);
  const practitioners = await Promise.resolve(
    practitionerIds.length > 0
      ? asyncActions(
          dispatch,
          tlcCardsActions.getActions().getList({ practitionerIds }),
        )
      : { items: [] },
  );

  const tlcPractitioners = practitioners.items.reduce(
    (curr, item) => ({ ...curr, [item.professional.practitionerId]: item }),
    {},
  );
  const newSuggestions = items.map(item => {
    let newItem = item;

    if (item.objectType === 'practitioner' && tlcPractitioners[item.objectID]) {
      newItem = {
        ...item,
        isTlcAvailable: true,
        centerId: tlcPractitioners[item.objectID].center.id,
      };
    }
    const { name, firstName, lastName } = item;
    return {
      ...newItem,
      label: `${name || `${firstName} ${lastName}`}`,
      id: item.objectID,
    };
  });
  dispatch(suggestionsActions.setItems(newSuggestions));
};
