import React from 'react';
import { Button } from '@mui/material';
import sanitize from 'sanitize-html';
import { IconVideoCamera } from '@maiia/design-system';
import SpecialityItem from './SpecialityItem';
import PractitionerItem from './PractitionerItem';
import CenterItem from './CenterItem';
import { useTranslation } from '@/src/i18n';

type SuggestionItemProps = {
  suggestion: any;
  highlightedText: any;
};

const SuggestionItem = ({
  suggestion,
  highlightedText,
}: SuggestionItemProps) => {
  const { t } = useTranslation();

  if (suggestion.objectType === 'speciality') {
    return <SpecialityItem {...suggestion} highlightedText={highlightedText} />;
  }
  if (suggestion.objectType === 'practitioner') {
    return (
      <PractitionerItem {...suggestion} highlightedText={highlightedText} />
    );
  }
  if (suggestion.objectType === 'center') {
    return <CenterItem {...suggestion} highlightedText={highlightedText} />;
  }
  if (suggestion.name === 'TLC') {
    return (
      <Button
        fullWidth
        variant="text"
        color="secondary"
        startIcon={<IconVideoCamera />}
        sx={theme => ({
          justifyContent: 'left',
          paddingLeft: 0,
          fontSize: theme.typography.body1.fontSize,
        })}
      >
        {t(suggestion.label)}
      </Button>
    );
  }

  return (
    <div
      className={`search-item item--default item-${suggestion.objectID}`}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: sanitize(highlightedText.join(' ') || suggestion.label, {
          allowedTags: ['em'],
        }),
      }}
    />
  );
};

export default SuggestionItem;
