import React from 'react';
import sanitize from 'sanitize-html';
import { analyticsEvent } from '@/src/analytic_tag_manager';

type Props = {
  highlightedText?: any;
  shortName: string;
  label: string;
};

const SpecialityItem = ({ highlightedText, shortName, label }: Props) => (
  <div
    className={`search-item item--spe item-${shortName}`}
    onClick={() => {
      analyticsEvent({
        category: 'SearchForm',
        action: 'ClickSpecialty',
        label: shortName,
      });
    }}
  >
    <span
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: sanitize(highlightedText.join(' ') || label, {
          allowedTags: ['em'],
        }),
      }}
    />
  </div>
);

export default SpecialityItem;
