import { useQuery } from '@tanstack/react-query';
import { ResultPageSearchSuggestionDTO } from '@maiia/model/generated/model/api-patient/api-patient';
import { DEFAULT_SUGGESTIONS } from '@/src/constants';

const DEFAULT_SUGGESTIONS_INDEX_BY_SHORTNAME = DEFAULT_SUGGESTIONS.reduce(
  (prev, curr, index) => ({ ...prev, [curr.shortName]: index }),
  {},
);

const useDefaultSuggestions = () =>
  useQuery(
    ['defaultSuggestions'],
    () =>
      fetch('/api/pat-public/hcd/search/suggestions')
        .then(r => r.json())
        .then((d: ResultPageSearchSuggestionDTO) => {
          const items = (d.items ?? [])
            .map(item => ({
              ...item,
              index:
                DEFAULT_SUGGESTIONS_INDEX_BY_SHORTNAME[
                  item?.shortName ?? 'unknown'
                ] ?? (d.total ?? 0) + 1,
            }))
            .sort((a, b) => a.index - b.index);

          return [DEFAULT_SUGGESTIONS[0], ...items];
        }),
    { placeholderData: DEFAULT_SUGGESTIONS },
  );

export default useDefaultSuggestions;
